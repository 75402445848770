import
	{
		HttpEvent,
		HttpHandler,
		HttpInterceptor,
		HttpRequest
	} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { MyCookieService } from './services/my-cookie.service';
// import { AdService } from './services/ad.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor
{
	constructor (
		private cookieService: MyCookieService,
		// private readonly authService: AdService,
	)
	{}

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>>
	{
		const token: string = this.cookieService.get('authToken-sust');

		let authReq = req.clone();
		if (token)
		{
			authReq = req.clone(
				{
					headers: req.headers.set('Authorization', 'Bearer ' + token)
				}
			);
		}

		return next.handle(authReq);
	}
}
