import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Message, MessageService } from 'primeng/api';

@Injectable({
	providedIn: 'root'
})
export class MyMessageService
{
	constructor (
		private messageService: MessageService,
	) { }

	add(msg: Message)
	{
		this.messageService.add(msg);
	}

	error(error: HttpErrorResponse)
	{
		let msg: Message = { 
			severity: 'error',
			summary: 'Houve um erro na requisição!',
			detail: `${error.status} - ${error.error ? error.error.message : error.statusText}`,
		};
		this.messageService.add(msg);
	}
	warn(titulo:string, mensagem: string)
	{
		let msg: Message = { 
			severity: 'warn',
			summary: titulo ?? 'Atenção!',
			detail: mensagem,
		};
		this.messageService.add(msg);
	}

	clear(key?: string)
	{
		this.messageService.clear(key);
	}
}
