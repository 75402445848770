export const environment = {
	production: true,
	AZURE_APP_ID: '75271591-6478-496a-a470-2c71b2f672ee',
	AZURE_OBJ_ID: '0b87409d-83bd-4302-92f2-150e1c895a2c',
	AZURE_CLIENT_ID: 'f6367f17-e1f9-4bd8-8786-b4b60037a0ef',
	AZURE_CLIENT_KEY: 'ij88Q~uZL23i1LVSA.Z6HDD4Rt5qimODv3HVPdzk',
	AZURE_CLIENT_SECRET: '3N78Q~_6dlIohcBjtCqDerh3gBw8k_1I~-ROeb2P',
	AZURE_AD_AUDIENCE: 'f6367f17-e1f9-4bd8-8786-b4b60037a0ef',
	AZURE_AD_TENANTID: 'de23d5f0-ccac-4c84-81d6-2892a8c055aa',
	AZURE_AD_DOMAIN: 'stj.jus.br',
	DISABLE_AUTH: false,
	
	HASH_KEY: "CAUTninja",
};
