import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from '../../../../environment/environment';
import { AudRole, MSPhoto, MSProfile, MSRole, MixedRoles } from '../../../types/auth.types';
import { Unidade } from '../../../types/servidor.types';
import { MyMessageService } from '../../shared/services/my-message.service';
import { UserModel } from './models/user.model';
import { MyCookieService } from './my-cookie.service';
import { UserService } from './user.service';
import { Router } from '@angular/router';
const Encoder = require('../../../libs/encoder');

@Injectable({
	providedIn: 'root'
})
export class AuthService
{
	constructor(
		private userService: UserService,
		private cookieService: MyCookieService,
		private router: Router,
		private http: HttpClient,
		protected msgService: MyMessageService,
	)
	{
		this.userService.currentToken.subscribe(async (token: any) =>
		{
			if (token)
			{
				await this.userService.validateToken(token).then(async (userData: UserModel | undefined) =>
				{
					this.userService.currentUser.next(userData);
					this.currentUser.next(userData);
					this.profile.next(await this.getFullProfile());
					this.roles.next(await this.getMyRoles());
					this.unidades.next(await this.getMyUnidades() as Unidade[]);
					this.photo.next(await this.getPhoto());
				});
				// this.userService.getGoogleProfile();
			}
			else
			{
				this.userService.currentUser.next(undefined);
				this.currentUser.next(undefined);
				this.photo.next(undefined);
				this.unidades.next([]);
			}
		});
	}

	currentUser: BehaviorSubject<UserModel | undefined> = new BehaviorSubject<UserModel | undefined>(undefined);
	currentPermissions: BehaviorSubject<any> = new BehaviorSubject<any>({});
	profile: BehaviorSubject<MSProfile | undefined> = new BehaviorSubject<MSProfile | undefined>(undefined);
	photo: BehaviorSubject<MSPhoto | undefined> = new BehaviorSubject<MSPhoto | undefined>(undefined);
	roles: BehaviorSubject<MixedRoles> = new BehaviorSubject<MixedRoles>({ roles: [], msRoles: [] });
	unidades: BehaviorSubject<Unidade[]> = new BehaviorSubject<Unidade[]>([]);

	async getFullToken(tempToken: string, userId: string)
	{
		return new Promise((resolve, reject) =>
		{
			let subs = this.http.get(`/api-sust/auth/token/?uid=${userId}&token=${tempToken}`).subscribe({
				next: (token: any) =>
				{
					this.setTokenCookie(token.sessionToken)
						.then(res =>
						{
							resolve(token);
						})
						.catch(error =>
						{
							console.log(error);
							reject(error);
						})
					// reject('teste');
				},
				error: (error: any) =>
				{
					reject(error);
				},
				complete: () =>
				{
					subs.unsubscribe();
				},
			})
		});
	}

	async setTokenCookie(token: string)
	{
		await this.cookieService.set('authToken-sust', token);
		this.cookieService.set('authToken-sust', token);
		this.userService.currentToken.next(token);
		const cookieToken = this.cookieService.get('authToken-sust');
		// console.log(cookieToken);
		return token == cookieToken;
	}

	getToken()
	{
		const token = this.cookieService.get('authToken-sust');
		return token;
	}

	protected async _genericGet(url: string, args?: any): Promise<any>
	{
		return new Promise((resolve, reject) =>
		{
			this.http.get(url, { withCredentials: true, ...args })
				.subscribe({
					next: (value) =>
					{
						resolve(value)
					},
					error: (err: HttpErrorResponse) =>
					{
						this.msgService.add({ severity: 'warn', summary: err.error.message });
						if (err.status == 401)
						{
							this.cookieService.delete('authToken-sust');
							this.currentUser.next(undefined);
							this.currentPermissions.next([]);
							// return this.router.navigate(['/']);
						}
						return reject(err);
					},
					complete()
					{
						return;
					},
				})
		})
	}

	protected async _genericPost(url: string, body: any, args?: any): Promise<any>
	{
		return new Promise((resolve, reject) =>
		{
			this.http.post(url, body, { withCredentials: true, ...args })
				.subscribe({
					next: (value) =>
					{
						resolve(value)
					},
					error: (err: HttpErrorResponse) =>
					{
						this.msgService.add({ severity: 'warn', summary: err.error?.message });
						// if (err.status == 401)
						// {
						// 	this.cookieService.delete('authToken-sust');
						// 	this.currentUser.next(undefined);
						// 	this.currentPermissions.next([]);
						// 	return this.router.navigate(['/']);
						// }
						return reject(err);
					},
					complete()
					{
						return;
					},
				})
		})
	}

	async getProfileImage(): Promise<any>
	{
		return await this.userService.getProfilePicture();
	}

	async getFullProfile(): Promise<any>
	{
		if (environment.DISABLE_AUTH)
		{
			return staticProfile;
		}
		let dados = await this._genericGet(`/api-sust/auth/full-profile`);
		return dados;
	}

	async getPhoto(): Promise<MSPhoto | undefined>
	{
		if (environment.DISABLE_AUTH) return staticImg;

		if (this.currentUser.value === undefined) return undefined;

		let dados;
		try
		{
			dados = await this._genericGet(`/api-sust/auth/photo`);
		} catch (error: any)
		{
			if (error.status == 401)
			{
				this.currentUser.next(undefined);
				this.roles.next({});
				this.profile.next(undefined);
				this.cookieService.deleteAll();
				this.router.navigate(['/']);
			}
		}
		return dados;
	}

	async login()
	{
		window.location.href = "/api-sust/auth/microsoft";
	}

	async loginManual(email: string, senha: string): Promise<any>
	{
		let url = `/api-sust/auth/local`;

		try
		{
			let dados = await this._genericPost(url, { username: email, password: senha });

			return { status: true, data: dados, msg: `Login efetuado com sucesso!` };
		} catch (error: HttpErrorResponse | any)
		{
			return { status: false, msg: error.error ? error.error.message : error.message };
		}

	}

	async localLogout()
	{
		await this.cookieService.delete('authToken-sust');
		await this.cookieService.deleteAll();
		this.userService.currentToken.next('');
		this.profile.next(undefined);
		this.photo.next(undefined);
		this.unidades.next([]);
		// this.currentGroup.next(undefined);
		// this.currentUser.next(undefined);
		this.currentUser.next(undefined);
		this.currentPermissions.next(undefined);
		this.roles.next({
			roles: [],
			msRoles: [],
		});
		this.router.navigate(['/']);
	}

	async logout()
	{
		let url = `/api-sust/auth/logout`;

		try
		{
			let dados = await this._genericGet(url);
			// if (dados?.sucesso) await this.localLogout();

			return { status: true, data: dados, msg: `Logout efetuado com sucesso!` };
		} catch (error: HttpErrorResponse | any)
		{
			return { status: false, msg: error.error ? error.error.message : error.message };
		}
	}

	async getMyRoles(args?: { simples?: boolean }): Promise<MixedRoles | any>
	{
		// console.log(this.currentUser.value);

		let mixedRoles: MixedRoles = {
			roles: [],
			msRoles: [],
		};
		if (environment.DISABLE_AUTH)
		{
			if (window.location.hostname == 'localhost') mixedRoles.msRoles?.push(staticRoleAdmin);
			mixedRoles.msRoles?.push(staticRoleAud);
			mixedRoles.msRoles?.push(staticRoleSad);
			return mixedRoles;
		}
		else
		{
			try
			{
				let dados = await this._genericGet(`/api-sust/auth/roles`);
				mixedRoles.roles = dados?.roles as AudRole[];
				mixedRoles.msRoles = dados?.ms_roles as MSRole[];
			} catch (error: any)
			{
				// console.log(error);
				this.msgService.error(error);
				this.cookieService.deleteAll();
				this.userService.currentUser.next(undefined);
				this.userService.currentToken.next('');
				this.router.navigate(['/auth', 'logout']);
			}
		}

		if (args?.simples)
		{
			let msRoles = mixedRoles.msRoles?.map((item: MSRole) => item.value);
			let audRoles = mixedRoles.roles?.map((item: AudRole) => item.roleId);
			return { roles: audRoles, msRoles: msRoles };
		}

		return mixedRoles;
	}

	async getMyUnidades(args?: { simples?: boolean }): Promise<(Unidade | string)[]>
	{
		if (environment.DISABLE_AUTH)
		{
			return [];
		}
		let dados: Unidade[] = [];

		if (this.currentUser.value === undefined) return [];

		try
		{
			dados = await this._genericGet(`/api-sust/auth/unidades`);
		} catch (error)
		{
			console.log(error)
		}
		if (args?.simples) dados.map(item => item.sigla_unidade);

		return dados;
	}

	private async _getPermissions(currentGroupId?: number)
	{
		if (!currentGroupId) return [];
		let roles: any = [];

		let saida: { [key: string]: any } = {};

		for (let roleData of roles)
		{
			saida[roleData.roleId] = roleData.role;
		}
		return saida;
	}

	async hasRole(roles: string[]): Promise<boolean>
	{
		let mixedRoles = this.roles.value;
		// let permissions = this.currentPermissions.value;

		// const currentGroupId = Number(this.cookieService.get('currentGroupId'));
		// if (!permissions || Object.keys(permissions).length == 0)
		// {
		// 	permissions = await this._getPermissions(currentGroupId);
		// 	this.currentPermissions.next(permissions);
		// }
		let AudRoles: (string | undefined)[] | undefined = mixedRoles.roles?.map((item: AudRole) => item.roleId?.toLocaleLowerCase());
		let MsRoles: (string | undefined)[] | undefined = mixedRoles.msRoles?.map((item: MSRole) => item.value?.toLocaleLowerCase());
		// Se for superadmin, sempre tem permissão
		if (MsRoles?.includes('admin.all')) return true;


		// Verifica se o usuário possui ao menos uma das permissões necessárias
		for (let role of roles)
		{
			if (AudRoles?.includes(role.toLocaleLowerCase())) return true;
		}

		return false;
	}

	async sendResetPassword(email: string): Promise<{ status: boolean, msg: string, data?: any }>
	{
		let url = `/api-sust/auth/send-reset`;

		try
		{
			const body = { email };
			let dados = await this._genericPost(url, body);
			// if (dados?.sucesso) await this.localLogout();

			return { status: true, data: dados, msg: `Email de recuperação de senha enviado com sucesso!` };

		} catch (error: HttpErrorResponse | any)
		{
			return { status: false, msg: error.error ? error.error.message : error.message };
		}
	}

	encodePassword(senha: string)
	{
		let hash = Encoder.encrypt(senha);
		return hash;
	}

	decodePassword(senhaHash: string)
	{
		let senha = Encoder.decrypt(senhaHash);
		return senha;
	}

	async resetPassword(data: { email: string, senha: string, token?: string, senhaAnterior?: string })
	{
		let { email, senha, token, senhaAnterior } = data;
		let url = `/api-sust/auth/reset`;

		if (!token)
		{
			url = `/api-sust/auth/reset/notoken`;
			// return { status: false, msg: 'Token não informado!' };
		}

		try
		{
			let senhaHash = senha ? this.encodePassword(senha) : null;
			if (senhaAnterior) senhaAnterior = this.encodePassword(senhaAnterior);

			const body = { email, senha: senhaHash, token, senhaAnterior };
			let dados = await this._genericPost(url, body);

			return { status: true, data: dados, msg: `Senha modificada com sucesso!` };

		} catch (error: HttpErrorResponse | any)
		{
			return { status: false, msg: error.error ? error.error.message : error.message };
		}
	}
}

const staticImg: MSPhoto = {
	contentType: "image/png",
	etag: 'W/\"7fa0c0ed0e317ed5dc6c1cbd335f86b3b380105d15df8ce6f26b23df5c442210\"',
	height: 120,
	id: "default",
	imagem: "/assets/img/avatar.png",
	width: 120,
}

const staticProfile: MSProfile = {
	businessPhones: [],
	displayName: 'AUD-PREVINE',
	givenName: 'AUD-PREVINE',
	id: '0',
	jobTitle: '',
	mail: 'aud-previne@stj.jus.br',
	mobilePhone: "",
	officeLocation: '',
	preferredLanguage: 'pt-BR',
	surname: 'AUD-PREVINE',
	userPrincipalName: 'AUD-PREVINE',
	photo: undefined,
};

const staticRoleAdmin: MSRole = {
	"id": "1",
	"deletedDateTime": undefined,
	"appRoleId": "d50d54e2-a57b-43ac-8b69-3b65574b765e",
	"createdDateTime": new Date(),
	"principalDisplayName": "",
	"principalId": "0",
	"principalType": "User",
	"resourceDisplayName": "AUD-Previne",
	"resourceId": "0b87409d-83bd-4302-92f2-150e1c895a2c",
	"allowedMemberTypes": [
		"User"
	],
	"description": "Administradores gerais",
	"displayName": "Administrador",
	"isEnabled": true,
	"origin": "Application",
	"value": "Admin.All"
};
const staticRoleAud: MSRole = {
	"id": "2",
	"deletedDateTime": undefined,
	"appRoleId": "a64ca0aa-8d37-4667-918c-c5774cd8f8a9",
	"createdDateTime": new Date(),
	"principalDisplayName": "",
	"principalId": "0",
	"principalType": "User",
	"resourceDisplayName": "AUD-Previne",
	"resourceId": "0b87409d-83bd-4302-92f2-150e1c895a2c",
	"allowedMemberTypes": [
		"User"
	],
	"description": "Membro da área de Auditoria",
	"displayName": "Auditoria - Membro",
	"isEnabled": true,
	"origin": "Application",
	"value": "Aud.All"
};
const staticRoleSad: MSRole = {
	"id": "3",
	"deletedDateTime": undefined,
	"appRoleId": "123456",
	"createdDateTime": new Date(),
	"principalDisplayName": "",
	"principalId": "0",
	"principalType": "User",
	"resourceDisplayName": "AUD-Previne",
	"resourceId": "0b87409d-83bd-4302-92f2-150e1c895a2c",
	"allowedMemberTypes": [
		"User"
	],
	"description": "Membro da Sad",
	"displayName": "Sad - Membro",
	"isEnabled": true,
	"origin": "Application",
	"value": "Sad.All"
};
