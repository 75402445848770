import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
// import { CookieService } from 'ngx-cookie-service';
// import { UserService } from '../services/user.service';
import { Observable } from 'rxjs';
import { UserService } from '../services/user.service';
import { environment } from '../../../../environment/environment';

export const authGuard: CanActivateFn = (route, state) =>
{
	const router = inject(Router);
	const cookieService = inject(CookieService);
	const userService = inject(UserService);

	if (environment.DISABLE_AUTH) return true;
	return new Observable((subs) =>
	{
		let token = cookieService.get('authToken-sust');
		if (token) 
		{
			userService.validateToken(token).then(userData =>
			{
				subs.next(true);
				subs.complete();
			})
				.catch(error =>
				{
					if (error.status == 401)
					{
						subs.next(router.parseUrl('/auth/logout'));
						subs.complete();
					}
					
					subs.next(router.parseUrl('/auth/logout'));
					// subs.next(false);
					subs.error(error);
					subs.complete();
				})
				.finally(() =>
				{
					subs.complete();
				});
		}
		else
		{
			console.error('Token não encontrado!');

			// subs.next(router.parseUrl('/'));
			subs.next(router.parseUrl('/auth/logout'));
			subs.complete();
			throw new Error('Erro');
		}
	});
};
