const ALPHABET = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789 \'\"!@#$%¨&*()-_=+áàâãäÁÀÃÂÄéèêëÉÈÊËçÇíìîïÍÌÎÏóòõôöÓÒÕÔÖúùûüÚÙÛÜ´`[{]},<.>;:/?°ºª§¬¢£³²¹\\|~^';
const SUPER_SECRET_SALT = 'Seavestruzvoasseovadamatava';
const RAND_STRING = "Seeutivesse2pneuseraumabicicleta";

const generate_random_string = (length: any) =>
{
	// return ''.padEnd(length, RAND_STRING);
	let text = '';
	for (let i = 0; i < length; i++)
	{
		const nextCharacter = Math.floor(Math.random() * ALPHABET.length);
		text += ALPHABET.charAt(nextCharacter);
	}
	return text;
}

const reverse = (s: string) =>
{
	return s.split("").reverse().join("");
}

const merge_strings = (firstString: string, secondString: string) =>
{
	let mergedString = "";
	for (let i = 0; i < firstString.length; i++)
	{
		mergedString += firstString.charAt(i) + secondString.charAt(i);
	}
	return mergedString;
}

const unmerge_strings = (firstString: string) =>
{
	let unmergedString = "";
	for (let i = 0; i < firstString.length; i += 2)
	{
		unmergedString += firstString.charAt(i);// + secondString.charAt(i);
	}
	return unmergedString;
}

const shift_string = (text: string) =>
{
	let shiftedString = "";
	for (let i = 0; i < text.length; i++)
	{
		const shiftAmount = SUPER_SECRET_SALT.charAt(i % SUPER_SECRET_SALT.length).charCodeAt(0);
		shiftedString += ALPHABET.charAt((ALPHABET.indexOf(text.charAt(i)) + shiftAmount) % ALPHABET.length);
	}
	return shiftedString;
}

const unshift_string = (text: string) =>
{
	let shiftedString = "";
	for (let i = 0; i < text.length; i++)
	{
		const shiftAmount = SUPER_SECRET_SALT.charAt(i % SUPER_SECRET_SALT.length).charCodeAt(0);

		let charInd = (ALPHABET.indexOf(text.charAt(i))) - shiftAmount;
		while (charInd < 0) charInd += ALPHABET.length;

		// console.log(charInd, ALPHABET.charAt(charInd));
		// shiftedString += ALPHABET.charAt((ALPHABET.indexOf(text.charAt(i)) + shiftAmount) % ALPHABET.length);
		shiftedString += ALPHABET.charAt(charInd);
	}
	return shiftedString;
}

const find_checksum_char = (text: string) =>
{
	let totalCode = 0;
	for (let i = 0; i < text.length; i++)
	{
		totalCode += text.charAt(i).charCodeAt(0);
	}
	return ALPHABET.charAt(totalCode % ALPHABET.length);
}
module.exports = {
	encrypt: (textToEncrypt: string) => 
	{
		const inputLength = textToEncrypt.length;
		const randomString = generate_random_string(inputLength);
		let workString = reverse(textToEncrypt);
		workString = merge_strings(workString, randomString);
		workString = shift_string(workString);
		const tailChecksum = find_checksum_char(workString);
		workString += tailChecksum;
		const headChecksum = find_checksum_char(workString);
		const encryptedString = headChecksum + workString;
		return encryptedString;
	},
	decrypt: (encryptedString: string) => 
	{
		// const headChecksum = encryptedString.slice(0,1);
		// const tailChecksum = encryptedString.slice(-1);
		let workString = encryptedString.slice(1, -1);

		const inputLength = workString.length;
		// const randomString = generate_random_string(inputLength);
		
		workString = unshift_string(workString);
		workString = unmerge_strings(workString);
		workString = reverse(workString);
		return workString;
	},
}
