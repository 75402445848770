import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from '../../../../environment/environment';
import { UserModel } from './models/user.model';
import { MyCookieService } from './my-cookie.service';

@Injectable({
	providedIn: 'root'
})
export class UserService
{
	constructor (
		private http: HttpClient,
		private cookieService: MyCookieService,
	)
	{
		this.currentToken.next(this.cookieService.get('authToken-sust'));
	}

	currentUser: BehaviorSubject<UserModel | undefined> = new BehaviorSubject<UserModel | undefined>(undefined);
	currentToken: BehaviorSubject<string> = new BehaviorSubject('');

	validateToken(token?: string): Promise<UserModel | undefined>
	{
		return new Promise((resolve, reject) =>
		{
			if (environment.DISABLE_AUTH)
			{
				resolve({
					// user: {
					// },
					idPerson: 0,
					email: "aud-previne@stj.jus.br",
					emailVerified: false,
					id: "0",
					image: undefined,
					name: "AUD-PREVINE",
					token: "",
				})
				return;
			}
			let httpRequest;
			if (token)
			{
				httpRequest = this.http.get('/api-sust/auth/profile', { withCredentials: true, headers: new HttpHeaders({ Authorization: `Bearer ${token}` }) });
			}
			else
			{
				httpRequest = this.http.get('/api-sust/auth/profile', { withCredentials: true });
			}
			let sub = httpRequest.subscribe(
				{
					next: (userData) =>
					{
						resolve(userData as UserModel);
					},
					error: (error) =>
					{
						this.cookieService.delete('authToken-sust');
						// this.router.navigate(['/']);
						// if (error.status == 400)
						// {
						// 	this.cookieService.delete('authToken-sust');
						// 	this.router.navigate(['/']);
						// }
						// if (error.status == 404)
						// {
						// 	this.cookieService.delete('authToken-sust');
						// 	this.router.navigate(['/']);
						// }

						reject(error);
					},
					complete: () =>
					{
						sub.unsubscribe();
					}
				}
			);
		})
	}

	getProfile()
	{
		throw new Error('Not implemented!');
		let url: string = 'http://www.googleapis.com/oauth2/v1/userinfo';
		this.http.get(url, { withCredentials: true }).subscribe(
			{
				next: (res) =>
				{
					console.log(res);
				}
			}
		);
	}

	async getProfilePicture(): Promise<{ image: string, extension: string } | any>
	{
		throw new Error('Not implemented!');
		// let url: string = this.currentUser.value?.user?.image ?? '';
		// return await lastValueFrom(this.http.get(url, { withCredentials: true }));
	}

	async getUserRoles(userId: string): Promise<any[]>
	{
		throw new Error('Not implemented!');
		
	}

}
