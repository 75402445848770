import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ConfirmationService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogModule, DynamicDialogRef } from 'primeng/dynamicdialog';
import { MessageModule } from 'primeng/message';
import { LogTerminalService } from '../admin/components/log-terminal.service';
import { LogTerminalComponent } from '../admin/components/log-terminal/log-terminal.component';
import { AuthInterceptor } from '../auth/auth.interceptor';
import { PrimengModule } from './primeng/primeng.module';
import { DefaultService } from './services/-default.service';
import { MyMessageService } from './services/my-message.service';

@NgModule({
	declarations: [
		LogTerminalComponent,
	],
	imports: [
		CommonModule,
		MessageModule,
		PrimengModule,
		DynamicDialogModule,
	],
	providers: [
		MyMessageService,
		DefaultService,
		ConfirmationService,
		MyMessageService,
		DynamicDialogRef,
		DynamicDialogConfig,
		LogTerminalService,
		{ provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
	],
	exports: [
		CommonModule,
		HttpClientModule,
		FormsModule,
		ReactiveFormsModule,
		PrimengModule,
		DynamicDialogModule,
		MessageModule,
		LogTerminalComponent,
	]
})
export class MySharedModule { }
