import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

export type FormattedTerminalMessage = {
	msg: string;
	type?: 'command' | 'response';
	style?: any;
	dt?: Date;
}

@Injectable({
	providedIn: 'root'
})
export class LogTerminalService
{
	constructor (
	) { }
	
	commandHandler: Subject<string> = new Subject();
	responseHandler: Subject<string | FormattedTerminalMessage> = new Subject<string | FormattedTerminalMessage>();
	
	sendCommand(command: string)
	{
		// console.log('command', command);
		this.commandHandler.next(command);
	}
	
	sendResponse(response: string | FormattedTerminalMessage)
	{
		// console.log('response', response);
		this.responseHandler.next(response);
	}
	
	clear() {
		this.commandHandler.next('clear');
	}
}
