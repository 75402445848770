import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { AccordionModule } from 'primeng/accordion';
import { ConfirmationService, MessageService } from 'primeng/api';
import { AvatarModule } from 'primeng/avatar';
import { AvatarGroupModule } from 'primeng/avatargroup';
import { BadgeModule } from 'primeng/badge';
import { BlockUIModule } from 'primeng/blockui';
import { ButtonModule } from 'primeng/button';
import { ButtonGroupModule } from 'primeng/buttongroup';
import { CalendarModule } from 'primeng/calendar';
import { CardModule } from 'primeng/card';
import { CascadeSelectModule } from 'primeng/cascadeselect';
import { ChartModule } from 'primeng/chart';
import { ChipModule } from 'primeng/chip';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { ContextMenuModule } from 'primeng/contextmenu';
import { DataViewModule } from 'primeng/dataview';
import { DialogModule } from 'primeng/dialog';
import { DragDropModule } from 'primeng/dragdrop';
import { DropdownModule } from 'primeng/dropdown';
import { DynamicDialogConfig, DynamicDialogModule, DynamicDialogRef } from 'primeng/dynamicdialog';
import { FieldsetModule } from 'primeng/fieldset';
import { IconFieldModule } from 'primeng/iconfield';
import { ImageModule } from 'primeng/image';
import { InputIconModule } from 'primeng/inputicon';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { ListboxModule } from 'primeng/listbox';
import { MegaMenuModule } from 'primeng/megamenu';
import { MenuModule } from 'primeng/menu';
import { MenubarModule } from 'primeng/menubar';
import { MessageModule } from 'primeng/message';
import { MessagesModule } from 'primeng/messages';
import { OrganizationChartModule } from 'primeng/organizationchart';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { PanelModule } from 'primeng/panel';
import { PickListModule } from 'primeng/picklist';
import { ProgressBarModule } from 'primeng/progressbar';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { ScrollTopModule } from 'primeng/scrolltop';
import { SelectButtonModule } from 'primeng/selectbutton';
import { SidebarModule } from 'primeng/sidebar';
import { SkeletonModule } from 'primeng/skeleton';
import { SlideMenuModule } from 'primeng/slidemenu';
import { SplitterModule } from 'primeng/splitter';
import { TableModule } from 'primeng/table';
import { TabMenuModule } from 'primeng/tabmenu';
import { TabViewModule } from 'primeng/tabview';
import { TagModule } from 'primeng/tag';
import { TimelineModule } from 'primeng/timeline';
import { ToastModule } from 'primeng/toast';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { ToolbarModule } from 'primeng/toolbar';
import { TreeSelectModule } from 'primeng/treeselect';
import { TreeTableModule } from 'primeng/treetable';

const modules = [
	AccordionModule,
	AvatarModule,
	AvatarGroupModule,
	BadgeModule,
	BlockUIModule,
	ButtonModule,
	ButtonGroupModule,
	CalendarModule,
	CardModule,
	CascadeSelectModule,
	ChartModule,
	ChipModule,
	ConfirmDialogModule,
	ConfirmPopupModule,
	ContextMenuModule,
	DataViewModule,
	DialogModule,
	DragDropModule,
	DropdownModule,
	DynamicDialogModule,
	FieldsetModule,
	IconFieldModule,
	ImageModule,
	InputIconModule,
	InputNumberModule,
	InputSwitchModule,
	InputTextModule,
	InputTextareaModule,
	ListboxModule,
	MegaMenuModule,
	MenuModule,
	MenubarModule,
	MessageModule,
	MessagesModule,
	OrganizationChartModule,
	OverlayPanelModule,
	PanelModule,
	PickListModule,
	ProgressBarModule,
	ProgressSpinnerModule,
	ScrollPanelModule,
	ScrollTopModule,
	SelectButtonModule,
	SidebarModule,
	SkeletonModule,
	SlideMenuModule,
	SplitterModule,
	TableModule,
	TabMenuModule,
	TabViewModule,
	TagModule,
	TimelineModule,
	// TerminalModule,
	ToastModule,
	ToggleButtonModule,
	ToolbarModule,
	TreeSelectModule,
	TreeTableModule,
];

@NgModule({
	declarations: [],
	imports: [
		CommonModule,
		MessageModule,
		MessagesModule,
		...modules,
	],
	schemas: [
		CUSTOM_ELEMENTS_SCHEMA,
	],
	exports: [
		...modules,
		MessageModule,
		MessagesModule,
	],
	providers: [
		ConfirmationService,
		DynamicDialogRef,
		DynamicDialogConfig,
		MessageService,
	],
})
export class PrimengModule { }
