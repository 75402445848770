import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { jwtDecode } from 'jwt-decode';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
	providedIn: 'root'
})
export class MyCookieService
{
	constructor (
		@Inject(CookieService) private readonly cookieService: CookieService,
		@Inject(PLATFORM_ID) private platformId: Object
	)
	{
		if (isPlatformBrowser(this.platformId))
		{
			if (window) this.domain = (new URL(window?.location?.href)).host;
		}
	}

	domain: string = '';

	set(varname: string, value: string, expireDays?: number)
	{
		let dt = new Date();
		if (!expireDays)
		{
			// Data de expiração padrão, em dias
			expireDays = 1;
		}
		dt.setTime(dt.getTime() + 1000 * 60 * 60 * 24 * expireDays);
		// const saida = this.cookieService.set(varname, value, expireDays < 0 ? undefined : dt, '/', this.domain, false, 'Lax');
		const saida = this.cookieService.set(varname, value, expireDays < 0 ? undefined : dt, '/');
		// console.log(this.getAll());
		return saida;
	}

	get(varname: string)
	{
		return this.cookieService.get(varname);
	}

	getAll()
	{
		return this.cookieService.getAll()
	}

	deleteAll()
	{
		return this.cookieService.deleteAll()
	}

	delete(name: string)
	{
		return this.cookieService.delete(name, '/', this.domain, false, 'Lax');
	}

	getDecodedAccessToken(token?: string): any
	{
		try
		{
			if (!token) token = this.get('authToken-sust');
			return jwtDecode(token);
		} catch (Error)
		{
			return null;
		}
	}
}
