import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DefaultService {

  constructor(
		protected http: HttpClient,
	) { }
	
	protected _genericGet(url: string, args?: any): Promise<any>
	{
		return new Promise((resolve, reject) =>
		{
			this.http.get(url, { withCredentials: true, ...args })
				.subscribe({
					next: (value) =>
					{
						resolve(value)
					},
					error: (err:HttpErrorResponse) => 
					{
						reject(err);
					},
					complete()
					{
						return;
					},
				})
		})
	}
	
	protected _genericPost(url: string, body: any, args?: any): Promise<any>
	{
		return new Promise((resolve, reject) =>
		{
			this.http.post(url, body, { withCredentials: true, ...args })
				.subscribe({
					next: (value) =>
					{
						resolve(value)
					},
					error: (err:HttpErrorResponse) => 
					{
						reject(err);
					},
					complete()
					{
						return;
					},
				})
		})
	}
	
	protected _genericDelete(url: string, args?: any): Promise<any>
	{
		return new Promise((resolve, reject) =>
		{
			this.http.delete(url, { withCredentials: true, ...args })
				.subscribe({
					next: (value) =>
					{
						resolve(value)
					},
					error: (err:HttpErrorResponse) => 
					{
						reject(err);
					},
					complete()
					{
						return;
					},
				})
		})
	}

}
